// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateCloudProjectIaasCloudStorageConfigurationScss from "./CreateCloudProjectIaasCloudStorageConfiguration.scss";

var css = CreateCloudProjectIaasCloudStorageConfigurationScss;

function CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector(props) {
  var unitStatus = props.unitStatus;
  var inputStatus = props.inputStatus;
  var domId = props.domId;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Label.make, {
                      forId: domId,
                      children: props.label
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(TextField.make, {
                              id: domId,
                              value: props.value,
                              status: Belt_Option.flatMap(inputStatus, (function (x) {
                                      if (x.TAG === "Ok") {
                                        if (x._0 !== undefined) {
                                          return "Valid";
                                        } else {
                                          return ;
                                        }
                                      } else {
                                        return "Error";
                                      }
                                    })),
                              className: css.combinedFieldTextInput,
                              onBlur: props.onInputBlur,
                              onChange: props.onInputChange
                            }),
                        JsxRuntime.jsxs(Select.make, {
                              id: domId + "-unit",
                              value: props.unit,
                              status: Belt_Option.map(unitStatus, (function (x) {
                                      if (x.TAG === "Ok") {
                                        return "Valid";
                                      } else {
                                        return "Error";
                                      }
                                    })),
                              className: css.combinedFieldSelect,
                              wrapperClassName: css.combinedFieldSelectWrapper,
                              iconSize: "XXS",
                              iconColor: "GrayText",
                              onChange: props.onUnitChange,
                              onBlur: props.onUnitBlur,
                              children: [
                                JsxRuntime.jsx(Select.$$Option.make, {
                                      value: "",
                                      children: ""
                                    }),
                                Belt_Array.map(props.unitOptions, (function (unit) {
                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                    value: unit,
                                                    children: unit
                                                  }, unit);
                                      }))
                              ]
                            })
                      ],
                      className: css.combinedFieldsContainer
                    }),
                JsxRuntime.jsx(ProjectWizard.ValidationMessage.make, {
                      status: unitStatus,
                      className: css.combinedFieldsValidationMessage
                    }),
                JsxRuntime.jsx(ProjectWizard.ValidationMessage.make, {
                      status: inputStatus,
                      className: css.combinedFieldsValidationMessage
                    })
              ],
              className: css.field
            });
}

var make = CreateCloudProjectIaasCloudStorageConfiguration__InputWithUnitSelector;

export {
  css ,
  make ,
}
/* css Not a pure module */
