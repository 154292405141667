// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateCloudProjectIaasCloudStorageConfigurationScss from "./CreateCloudProjectIaasCloudStorageConfiguration.scss";

var css = CreateCloudProjectIaasCloudStorageConfigurationScss;

function CreateCloudProjectIaasCloudStorageConfiguration__DropdownField(props) {
  var __wrapperClassName = props.wrapperClassName;
  var status = props.status;
  var domId = props.domId;
  var wrapperClassName = __wrapperClassName !== undefined ? __wrapperClassName : "";
  var tmp;
  tmp = status !== undefined && status.TAG !== "Ok" ? ({
        TAG: "Error",
        _0: status._0
      }) : ({
        TAG: "Ok",
        _0: undefined
      });
  var tmp$1;
  tmp$1 = status !== undefined && status.TAG !== "Ok" ? "Error" : "Valid";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Label.make, {
                      forId: domId,
                      status: tmp,
                      className: props.isRequired ? css.required : "",
                      children: props.label
                    }),
                JsxRuntime.jsxs(Select.make, {
                      id: domId,
                      value: props.value,
                      status: tmp$1,
                      wrapperClassName: props.className,
                      iconSize: "XXS",
                      iconColor: "GrayText",
                      onChange: props.onChange,
                      onBlur: props.onBlur,
                      children: [
                        JsxRuntime.jsx(Select.DefaultOption.make, {
                              children: "Select"
                            }),
                        props.children
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizard.ValidationMessage.make, {
                      status: status
                    })
              ],
              className: Cx.cx([
                    css.field,
                    wrapperClassName
                  ])
            });
}

var make = CreateCloudProjectIaasCloudStorageConfiguration__DropdownField;

export {
  css ,
  make ,
}
/* css Not a pure module */
