// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              label: field.required("label", Json_Decode$JsonCombinators.string),
              value: field.required("value", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var ContractLength = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              contractLengths: field.required("contractLengths", Json_Decode$JsonCombinators.array(decoder)),
              rawStorageCapacities: field.required("rawStorageCapacities", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              diskTypes: field.required("diskTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              connectivityMethods: field.required("connectivityMethods", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              getRequestsUnits: field.required("getRequestsUnits", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              putRequestsUnits: field.required("putRequestsUnits", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              transferOutUnits: field.required("transferOutUnits", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              regions: field.required("regions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              cloudTypes: field.required("types", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              features: field.required("features", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              accessTypes: field.required("accessTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Options = {
  decoder: decoder$1,
  fromJson: fromJson$1
};

export {
  ContractLength ,
  Options ,
}
/* decoder Not a pure module */
